.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background: #ffffffa6;
  z-index: 99;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #e5ecfe;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #92bdf7;
    box-shadow: 0 0 0 0 #318dca;
  }

  50% {
    transform: scale(1.2);
    background-color: #336fbe;
    box-shadow: 0 0 0 10px #d2e7f3;
  }

  100% {
    transform: scale(0.8);
    background-color: #0d6deb;
    box-shadow: 0 0 0 0 #ffffff;
  }
}
