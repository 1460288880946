.navbar {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  position: relative;
  color: black;
  width: 100vw;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}

.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.css-1pqm26d-MuiAvatar-img {
  cursor: pointer;
}

.navbar-links p.username {
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-links li a:hover {
  text-decoration: underline;
}

.navbar-logo {
  width: 150px;
}

.vysystemlogo {
  width: 150px;
}

.disagree {
  background-color: #ffffff;
  color: #0648a0;
  width: 30%;
  height: 40px;
  border: cornsilk;
  border-radius: 5px;
  position: relative;
  border: 1px solid #0648a0;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  font-weight: 600;
  top: 15px;
  font-family: "Outfit", sans-serif;
}

.agree {
  background-color: #0648a0;
  color: #fff9f9;
  width: 30%;
  height: 40px;
  border: cornsilk;
  border-radius: 5px;
  position: relative;
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  cursor: pointer;
  top: 15px;
  margin: 25px 0px 0px 20px;
  font-weight: 500;
}

.agree.loading {
  background-color: #eceaeab9;
  color: #969696;
  cursor: not-allowed;
  border: 1px solid #969696;
  padding: 4px 0px 7px 18px;
}

.agree.loading span {
  position: absolute;
  left: -10px;
}

.delete-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.delete-popup-container.hidden {
  display: none;
}

.delete-popup-outer {
  height: 400px;
  width: 450px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
}

.delete-popup-heading {
  position: relative;
}

.delete-popup-heading h2 {
  position: fixed;
  background: #fff;
  width: 59%;
  margin: 0;
  padding: 20px 0;
  border-radius: 8px 8px 0 0;
  z-index: 999;
  color: #8c43c9;
  font-size: 22px;
}

.delete-popup-heading svg {
  position: absolute;
  z-index: 9999;
}

.delete-popup-div p {
  margin: 1px;
  color: #464646;
  font-weight: 500;
  font-size: 14px;
  text-transform: lowercase;
}

.delete-popup-div h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
}

.delete-popup {
  background-color: white;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  height: 440px;
  max-height: 100%;
  border-radius: 30px;
  margin-top: 0px;
  width: 100%;
}

.close-icon {
  top: 17px;
  right: 20px;
  cursor: pointer;
  color: #a7a7a7;
  background: none;
  border: none;
}

.close-icon:hover {
  color: red;
}

.close-div {
  width: 100%;
  text-align: center;
}

.close-div img.close-img {
  width: 40%;
  position: relative;
  margin-top: 30px;
}

.user-avatar {
  cursor: pointer;
}

.dropdown-container {
  position: relative;
  display: flex;
  width: auto;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  align-content: center;
}

.dropdown-content {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 2;
  padding: 12px 18px;
  min-width: 14vw;
}

.dropdown-content p {
  cursor: pointer;
  margin: 0;
  padding: 12px;
  color: #333;
  display: flex;
  align-items: center;
}


.dropdown-content p img {
  margin-right: 8px;
}


.dropdown-content p:hover {
  background-color: #f1f7ff;
}

.down-arrow {
  object-fit: contain;
  padding: 10px;
  cursor: pointer;
}

.update-history-icon,
.logout-icon {
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
}


.dropdown-content p:hover {
  background-color: #f1f7ff;
}

.dropdown-content p+p {
  border-top: 1px solid #ccc;
  padding-top: 8px;
}

@media screen and (max-width: 5000px) {
  .dropdown-content {
    width: 8vw;
  }
}

@media screen and (max-width: 3000px) {
  .dropdown-content {
    width: 9vw;
  }
}

@media screen and (max-width: 2400px) {
  .dropdown-content {
    width: 10vw;
  }
}

@media screen and (max-width: 2100px) {
  .dropdown-content {
    width: 11vw;
  }
}

@media screen and (max-width: 1800px) {
  .dropdown-content {
    width: 12vw;
  }
}

@media screen and (max-width: 1600px) {
  .dropdown-content {
    width: 15vw;
  }
}

@media screen and (max-width: 1500px) {
  .dropdown-content {
    width: 18vw;
  }
}

@media screen and (max-width: 1200px) {
  .dropdown-content {
    width: 24vw;
  }
}

@media screen and (min-width: 913px) and (max-width: 900px) {
  .delete-popup-outer {
    height: 380px;
    width: 350px;
  }

  .dropdown-content {
    width: 32vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 912px) {
  .delete-popup-outer {
    height: 380px;
    width: 350px;
  }

  .dropdown-content {
    width: 28vw;
  }

  .dropdown-container .user-avatar {
    width: 23px;
    height: 23px;
  }

  .navbar-links p.username {
    font-size: 15px;
  }

  .agree {
    margin-top: 12px;
    width: 40%;
  }

  .agree.loading {
    padding: 4px 0px 8px 15px;
  }

  .disagree {
    width: 40% !important;
  }
}

@media screen and (min-width: 580px) and (max-width: 767px) {
  .delete-popup-outer {
    height: 380px;
    width: 350px;
  }

  .agree {
    margin-top: 12px;
    width: 40%;
  }

  .agree.loading {
    padding: 4px 0px 8px 15px;
  }

  .disagree {
    width: 40% !important;
  }

  .dropdown-content {
    width: 36vw;
  }

  .dropdown-container .user-avatar {
    width: 23px;
    height: 23px;
  }

  .navbar-links p.username {
    font-size: 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 579px) {
  .delete-popup-outer {
    height: 300px;
    width: 300px;
  }

  .logout-animation {
    height: 140px !important;
    width: 300px !important;
  }

  .agree {
    margin-top: 8px;
    width: 40%;
  }

  .agree.loading {
    padding: 4px 0px 8px 15px;
  }

  .disagree {
    width: 40% !important;
  }

  .delete-popup-div h1 {
    font-size: 20px;
  }

  .dropdown-content {
    width: 36vw;
  }

  .dropdown-container .user-avatar {
    width: 23px;
    height: 23px;
  }

  .navbar-links p.username {
    font-size: 15px;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .vysystemlogo {
    width: 130px;
  }

  .dropdown-container .user-avatar {
    width: 24px;
    height: 24px;
  }

  .navbar-links p.username {
    font-size: 14px;
  }

  .dropdown-content {
    width: 46vw;
  }
}
@media screen and (min-width: 250px) and (max-width: 399px) {
  .vysystemlogo {
    width: 100px;
  }
  .dropdown-container .user-avatar {
    width: 24px;
    height: 24px;
  }
  .navbar-links p.username {
    font-size: 14px;
  }

  .dropdown-content {
    width: 46vw;
  }
}