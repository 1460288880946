
.Completion-main-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-radius: 10px;
  margin: auto;
  background: #f7f7f7;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
  margin-top: 5%;
  margin-bottom: 5%;
}

.Completion-main-div-content {
  text-align: center;
}
.Completion-header{
  font-size: 22px;
  margin-bottom: 20px;
}
.Completion-main-div div#lottie svg {
  width: 250px !important;
  height: 250px !important;
}

.Completion-main-div div#lottie {
  width: 250px !important;
  height: 250px !important;
}

.Upload-btn {
  color: white;
  background-color: #0648a0;
  border-radius: 5px;
  border: 1px solid;
  width: 110px;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  margin-top: 30px;
}
.Completion-main-div h1 {
  color: #303030b8;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.Completion-main-div p {
  color: #4092ee;
}