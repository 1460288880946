@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

::-ms-reveal {
  display: none;
}

.login {
  display: flex;
  min-height: 100vh;
}

.forgot-password-container {
  display: flex;
  min-height: 100vh;
}

.welcome-text {
  font-size: 3rem;
  font-weight: 500px;
  color: #ffffff;
  padding: 50px 25px;
  text-align: center;
}

.background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 51%;
  background-image: url("../Images/login_background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.signup-container .background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 51%;
  background-image: url("../Images/login_background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.signup-container .welcome-text {
  margin-top: 60px;
}

.signup-container .login-animation {
  margin-top: 15px;
}

.login-animation {
  padding: 15px;
  width: 36vw;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 10%;
  justify-content: center;
  margin-top: 10px;
}

.login-form span {
  color: #0075e5;
  font-size: 15px;
  cursor: pointer;
}

.login-heading {
  font-size: 30px;
  padding-bottom: 32px;
  margin-top: 30px;
}

.login-input-field {
  position: relative;
  margin-top: 5px;
  margin-bottom: 45px;
  width: 100%;
}

.Email-label,
.Password-label {
  font-weight: 500;
  font-size: 15px;
}

.form-input {
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 6px;
  padding: 1px 1px 0px 10px;
  border: 2px solid #c4c4c4;
  background: transparent;
  color: #000000;
  outline: none;
}

.form-input:hover {
  border-color: #d1e1fa;
}

.form-input:focus {
  border: 1px solid #0648a0;
}
.usertag-div {
  margin-bottom: 30px;
}
.eye-icon {
  position: absolute;
  right: 20px;
  top: 27px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 10px;
}

.eye-icon img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.error-message-login {
  position: absolute;
  left: 0;
  color: red;
  font-size: 14px;
  padding-top: 2px;
}

.login-button {
  display: block;
  width: 150px;
  height: 43px;
  margin-top: 9px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background: #105cc0;
  border: none;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 5px;
}

.login-button:hover {
  background: #0648a0;
}

.newuser-tag {
  color: black;
  font-weight: 500;
  font-size: 15px;
  padding: 20px 0 10px 0;
  font-weight: 500;
}

.newuser-tag-forgot-password {
  color: black;
  font-weight: 500;
  font-size: 15px;
  padding: 5px 0px;
  font-weight: 500;
}

.user-tag {
  color: black;
  font-weight: 500;
  padding: 20px 0px;
  text-align: left;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.logo2 {
  width: 110px;
  padding-left: 4px;
  margin-top: -7px;
}

.copyright {
  display: flex;
  color: #00000079;
  align-content: center;
  justify-content: center;
  margin: 15px 0px;
}

.cpyrt-text {
  font-weight: 500;
  color: black;
  font-size: 15px;
}

.login-button.loading {
  background-color: #eceaeab9;
  color: #969696;
  cursor: not-allowed;
  border: 1px solid #969696;
  padding: 10px 15px 10px 24px;
}
.login-button.loading span {
  position: absolute;
  left: -10px;
}

.lookbook-logo {
  display: none;
}

@media (max-width: 590.98px) {
  .lookbook-logo {
    display: block;
    width: 175px;
    height: 100px;
    align-self: center;
    padding: 20px 0;
  }

  .login-heading {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }
  .login {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }
  .forgot-password-container {
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    background-color: aliceblue;
  }
  .login-button {
    align-self: center;
    width: 170px;
    height: 45px;
    margin-top: 14px;
  }

  .login-input-field {
    margin-bottom: 60px;
  }

  .background-container {
    display: none;
  }

  .signup-container .background-container {
    display: none;
  }

  .newuser-tag {
    text-align: center;
  }

  .newuser-tag-forgot-password {
    text-align: center;
  }

  .user-tag {
    text-align: center;
  }

  .login-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .copyright {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .eye-icon {
    top: 27px;
  }
}

@media (min-width: 591px) and (max-width: 720.98px) {
  .lookbook-logo {
    display: block;
    width: 175px;
    height: 100px;
    align-self: center;
    padding: 20px 0;
  }

  .login-heading {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }
  .login {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }
  .forgot-password-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }

  .login-button {
    align-self: center;
    width: 170px;
    height: 45px;
    margin-top: 14px;
  }

  .login-input-field {
    margin-bottom: 50px;
  }

  .background-container {
    display: none;
  }

  .signup-container .background-container {
    display: none;
  }

  .newuser-tag {
    text-align: center;
  }

  .newuser-tag-forgot-password {
    text-align: center;
  }

  .user-tag {
    text-align: center;
  }

  .login-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .copyright {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .eye-icon {
    top: 27px;
  }
}

@media (min-width: 721px) and (max-width: 991.98px) {
  .lookbook-logo {
    display: block;
    width: 182px;
    height: 100px;
    align-self: center;
    padding: 20px 0;
  }

  .login-input-field {
    margin-bottom: 60px;
  }

  .login-heading {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }

  .login {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }
  .forgot-password-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }

  .login-button {
    align-self: center;
    width: 170px;
    height: 45px;
  }

  .background-container {
    display: none;
  }

  .signup-container .background-container {
    display: none;
  }

  .newuser-tag {
    text-align: center;
  }

  .newuser-tag-forgot-password {
    text-align: center;
  }

  .user-tag {
    text-align: center;
  }

  .login-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    width: 60%;
  }

  .copyright {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1109.98px) {
  .welcome-text {
    font-size: 42px;
    padding: 40px 25px;
  }

  .login-button {
    margin-top: 0px;
  }
  .newuser-tag {
    margin-bottom: 0px;
  }

  .newuser-tag-forgot-password {
    margin-bottom: 0px;
  }

  .signup-container .login-button {
    margin-top: 25px;
  }

  .logo2 {
    width: 100px;
  }
}
