.main-extract {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  height: 100vh;
}
.react-datepicker-wrapper {
  width: 100%;
}
.data-extract .education-textfield,
.data-extract .experience-summary,
.data-extract .month-year-select {
  margin-top: 7px;
}
.legend-div {
  display: flex;
  align-items: center;
  margin-right: 5%;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
}
.date-error-message {
  color: red;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill-container {
  width: 74%;
  margin: 5px 0px 15px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.workDuration-container {
  display: flex;
  gap: 6px;
  align-items: center;
  width: 75%;
}
.data.thumbsup.CustomDatePicker.compare-information {
  display: flex;
  justify-content: space-between;
}
.Extracted-Resume-Data .month-container {
  cursor: no-drop;
}
.Extracted-Resume-Data .year-container {
  cursor: no-drop;
}
.error-message-form {
  color: red;
  font-size: 14px;
  position: relative;
  bottom: 35px;
}
.error-message-year {
  color: red;
  font-size: 14px;
  position: relative;
  top: 0;
}
.error-message-summary {
  color: red;
  font-size: 14px;
  position: relative;
  bottom: 45px;
}

.error-message-form-year {
  color: red;
  font-size: 14px;
  position: relative;
  bottom: 35px;
}
.disabled-button {
  opacity: 0.5;
  cursor: no-drop !important;
}
.compare-label {
  width: 95px;
  color: #8b8b8b;
}

.addskill-container {
  display: flex;
  margin: 5px 0 20px 0;
}

.add-skill {
  width: 20vw;
  border: 1px solid #a19d9d;
  border-radius: 5px;
  height: 30px;
  outline: none;
  padding: 5px;
}
button.add-skill-btn {
  background: #004b9e;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 8px 0px;
  width: 10vw;
  height: 33px;
}
.skills
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root {
  color: #04040475;
}
.add-btn {
  font-size: 13px !important;
  border: 2px solid #5a9dec !important;
  color: #0865b0 !important;
  padding: 7px 13px 7px 27px;
  text-transform: capitalize;
  transition: all 0.5s ease 0s;
  border-radius: 0 !important;
  background: #eef7ff !important;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  position: relative;
  border-radius: 8px !important;
  cursor: pointer;
  margin-top: 10px;
}

.add-btn::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 11px;
  background-image: url(../Images/btn-plus.png);
  background-size: contain;
  left: 6px;
  background-repeat: no-repeat;
  top: 8px;
  transform: rotate(180deg);
}

.add-btn-disabled {
  font-size: 13px !important;
  border: 2px solid #5a9dec !important;
  color: #0865b0 !important;
  padding: 7px 13px 7px 27px;
  text-transform: capitalize;
  transition: all 0.5s ease 0s;
  border-radius: 0 !important;
  background: #eef7ff !important;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  position: relative;
  border-radius: 8px !important;
  cursor: no-drop !important;
  margin-top: 10px;
  opacity: 0.5;
}
.add-btn-disabled::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 11px;
  background-image: url(../Images/btn-plus.png);
  background-size: contain;
  left: 6px;
  background-repeat: no-repeat;
  top: 8px;
  transform: rotate(180deg);
}
.button-group {
  display: flex;
  grid-gap: 15px;
  margin-bottom: 20px;
}
.delete-btn {
  font-size: 13px !important;
  border: 2px solid #ca0000 !important;
  color: #ca0000 !important;
  padding: 7px 13px 7px 27px;
  text-transform: capitalize;
  transition: all 0.5s ease 0s;
  background: #ffebeb !important;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  position: relative;
  border-radius: 8px !important;
  cursor: pointer;
  margin-top: 10px;
}
.delete-btn::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 11px;
  background-image: url(../Images/trash.png);
  background-size: contain;
  left: 6px;
  background-repeat: no-repeat;
  top: 8px;
}
.month-year-select {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  width: 30vw;
  height: 50px;
  justify-content: space-between;
}

.select-label {
  margin-right: 10px;
}

.select-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.month-container {
  border: none;
  outline: none;
  cursor: pointer;
  width: 60px;
}

.year-container {
  border: none;
  outline: none;
  cursor: pointer;
  width: 55px;
}

.docx-viewer {
  width: 100%;
  height: 100vh;
  border: none;
}

.experience-education-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.side-headings {
  font-weight: bold;
  margin-bottom: 5px;
}
.compare-textfield {
  width: 100%;
}

.to-label {
  margin-right: 10px;
  margin-left: 10px;
}

.pg-viewer-wrapper .document-container {
  height: 50vh;
}

h4.side-headings {
  font-size: 16px;
  color: #333;
  margin: 0px;
  width: 100px;
  display: table-row-group;
}
.Extracted-Resume-Data-Header {
  display: flex;
  justify-content: space-between;
}
.Extracted-div {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #0000002b;
  position: sticky;
  top: -3px;
  z-index: 1;
  background: white;
  right: 21px;
  margin: -4px;
  padding: 5px;
  margin-bottom: 10px !important;
}
.person-data-header {
  position: sticky;
  top: -3px;
  z-index: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  word-break: break-word;
  right: 21px;
  margin: -4px;
  padding: 2px;
}
.dialog-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
}
.resume-data {
  flex: 1;
}
.Extracted-Resume-Data {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background: #f1f7ff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  height: auto;
  width: 80%;
  margin: auto;
  margin-top: 50px;
}
.selected {
  background-color: #6db66a;
  color: #f1f7ff;
  font-size: 15px;
  padding: 3px 5px;
  margin-bottom: 8px;
  cursor: pointer;
}
.selected::marker {
  color: #000;
}
.un-selected {
  background-color: #efd4d4;
  color: #0d0d0e;
  font-size: 15px;
  padding: 3px 5px;
  margin-bottom: 8px;
  cursor: pointer;
}
.Extracted-Resume-Data h3 {
  padding: 20px 0;
  margin: auto;
}
.Upload-Resumes {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding: 0 20px;
}
.resume {
  border: 1px solid #e6e6e6;
  width: 30%;
  height: 100vh;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  flex: 2;
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}
.resume-compare {
  border: 1px solid #e6e6e6;
  width: 30%;
  height: 100vh;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  flex: 1 1;
  overflow-x: hidden !important;
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}
.resume h5 {
  font-size: 20px;
  margin: 20px 0px;
  color: #0648a0;
  text-align: center;
  position: sticky;
  top: 0;
  background: white;
}
.resume-compare h5 {
  font-size: 20px;
  margin-top: 21px;
  margin-bottom: 10px;
  color: #0648a0;
  text-align: center;
  padding: 10px 0;
}
.resume-header {
  position: sticky;
  top: 0;
  background: white;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: white;
  border: 1px solid #e6e6e6;
}
.person-details {
  border: 1px solid #e6e6e6;
  width: 70%;
  border-radius: 5px;
  height: 100vh;
  background-color: white;
  overflow-y: scroll;
  padding: 0 5px;
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}

.person-details::-webkit-scrollbar,
.resume::-webkit-scrollbar,
.resume-compare::-webkit-scrollbar,
.pg-viewer-wrapper::-webkit-scrollbar,
.skills::-webkit-scrollbar,
.experience-summary::-webkit-scrollbar,
.recruiternotes::-webkit-scrollbar,
.summary::-webkit-scrollbar {
  width: 10px;
}

.person-details::-webkit-scrollbar-track,
.resume::-webkit-scrollbar-track,
.resume-compare::-webkit-scrollbar-track,
.pg-viewer-wrapper::-webkit-scrollbar-track,
.skills::-webkit-scrollbar-track,
.experience-summary::-webkit-scrollbar-track,
.recruiternotes::-webkit-scrollbar-track,
.summary::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #0649a027;
  border-radius: 10px;
}

.person-details::-webkit-scrollbar-thumb,
.resume::-webkit-scrollbar-thumb,
.resume-compare::-webkit-scrollbar-thumb,
.pg-viewer-wrapper::-webkit-scrollbar-thumb,
.skills::-webkit-scrollbar-thumb,
.experience-summary::-webkit-scrollbar-thumb,
.recruiternotes::-webkit-scrollbar-thumb,
.summary::-webkit-scrollbar-thumb {
  background: #0649a048;
  border-radius: 10px;
}

.person-details::-webkit-scrollbar-thumb:hover,
.resume::-webkit-scrollbar-thumb:hover,
.resume-compare::-webkit-scrollbar-thumb:hover,
.pg-viewer-wrapper::-webkit-scrollbar-thumb:hover,
.skills::-webkit-scrollbar-thumb:hover,
.experience-summary::-webkit-scrollbar-thumb:hover,
.recruiternotes::-webkit-scrollbar-thumb:hover,
.summary::-webkit-scrollbar-thumb:hover {
  background: #0648a0;
}

input {
  transition: background-color 10000s ease-in-out 0s;
}

input:focus {
  background-color: none !important;
}

.person-details::-moz-scrollbar {
  width: 12px;
  scrollbar-width: thin;
}
.person-details::-moz-scrollbar-thumb {
  background-color: #0649a048;
  scrollbar-width: thin;
}
.person-details::-moz-scrollbar-track {
  background-color: #0649a027;
  scrollbar-width: thin;
}
.pg-viewer-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}
.skills {
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}
input[type="number"] {
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pg-viewer-wrapper {
  overflow-y: unset !important;
}
.person-details h5 {
  font-size: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: #0648a0;
  margin-top: 20px;
}

.data {
  display: flex;
  align-items: baseline;
  margin: 5px 0 23px 0px;
  justify-content: flex-start;
}

.data-extract {
  display: flex;
  align-items: baseline;
  margin: 23px 0px 0px 0px;
  justify-content: flex-start;
}

.data-extract-experience {
  display: flex;
  align-items: baseline;
  margin: 0px 0px 0px 0px;
  justify-content: flex-start;
}

.extract-yearsofexperience {
  display: flex;
  align-items: flex-end !important;
}
.overall-experience-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.overall-experience-container .education-textfield {
  width: 100%;
}
.data h6,
.data-extract h6 {
  font-size: 16px;
  color: #333;
  margin: 0;
  width: 110px;
}

.data .compare-textfield,
.data-extract .compare-textfield {
  width: 30vw;
  padding: 7px 9px;
  border: 1px solid #a19d9d;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.data .compare-textfield:focus,
.data-extract .compare-textfield:focus {
  border: 1px solid #0648a0;
}

.data p,
.data-extract p {
  margin: 0;
  word-break: break-word;
}
.skills {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 5px;
  border: 1px solid #bab6b6;
  padding: 10px;
  border-radius: 5px;
  width: 30vw;
  height: 150px;
  overflow-y: scroll;
  border-radius: 10px;
}

.skills .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 13px;
  row-gap: 10px;
}

.skills .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 13px;
}

.red-box {
  padding: 9px;
  height: 30px;
  border-radius: 5px;
  background: #f01010c4;
  color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.green-box {
  padding: 9px;
  height: 30px;
  border-radius: 5px;
  background: #a6e0a1;
  color: rgb(15, 15, 15);
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.span-1 {
  background-color: #c7e1c1;
  line-height: 22px;
}
.span-2 {
  background-color: #efd4d4;
  line-height: 22px;
}
.data-skills {
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
}

.data-skills .content {
  width: 81%;
}

.data-skills .content .green {
  width: 100%;
  margin-bottom: 10px;
  line-break: auto;
}

.data-skills .content .red {
  width: 98%;
  padding: 5px 0;
  margin-bottom: 20px;
}

.Confirm-button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
  display: flex;
  column-gap: 30px;
}

.Confirm {
  color: white;
  background-color: #0648a0;
  border-radius: 5px;
  border: 1px solid;
  width: 110px;
  padding: 8px 25px;
  cursor: pointer;
}
.pdf-canvas canvas {
  width: 100%;
}
.list-files-inner svg {
  width: 40px;
  height: 19px;
}

.list-files-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.resume .list-files-icon svg {
  width: 49px !important;
  height: 43px !important;
  color: #004b9e;
}

.resume-compare .list-files-icon svg {
  width: 49px !important;
  height: 43px !important;
  color: #004b9e;
}

@media only screen and (min-width: 2701px) and (max-width: 3100px) {
  .Extracted-Resume-Data {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .Extracted-Resume-Data {
    width: 100%;
    margin: 0;
  }
  .Upload-Resumes {
    display: flex;
    flex-direction: column;
  }
  .resume {
    width: 100%;
  }
  .person-details {
    width: 100%;
  }
}

@media (max-width: 1040px) {
  .add-resume-outer {
    display: flex;
    flex-direction: column;
  }

  .full-screen-div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .full-screen-div .listed-files-container {
    width: 100%;
  }
}

.dialog-outer-container .dialog-container {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  border: 1px solid #ccc;
  background: #fff;
  width: 100vw;
  margin: auto;
  flex-direction: row-reverse;
}

.dialog-outer-container .resume img {
  width: 87%;
  padding: 20px;
}
.dialog-outer-container .resume {
  flex: 1 1;
}
.dialog-outer-container .person-details {
  border: 1px solid #e6e6e6;
  width: 100%;
}
.dialog-outer-container h2 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0648a0;
  text-align: center;
}

.Extracted-div svg {
  position: absolute;
  left: 1vw;
  cursor: pointer;
  color: #0051ae;
}

.update-div {
  display: flex;
  justify-content: center;
}
.update {
  background: #105cc0;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 15px;
  height: 43px;
  margin: 10px 0px;
  outline: none;
  padding: 10px 5px;
  width: 150px !important;
}
.compare {
  color: #0648a0;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #0648a0;
  width: 90px;
  height: 35px;
  padding: 6px;
  font-weight: 600;
  float: right;
  cursor: pointer;
}
.green-text {
  color: #007f19;
  font-weight: bolder;
}
.experience-summary,
.summary,
.recruiternotes {
  width: 30vw;
  height: 130px;
  padding: 10px;
  font-size: 14px;
  resize: none;
  border: 1px solid #0000004d;
  border-radius: 5px;
  margin-bottom: 5px;
}

.person-data textarea:focus {
  outline: none;
}
.person-data li {
  padding-bottom: 10px;
  word-break: break-word;
}
.person-data li.green-text {
  width: fit-content;
  margin-bottom: 6px;
}
p.side-heading {
  color: steelblue;
}
.person-data {
  padding: 0 10px;
}

.list-files-inner.active-file {
  background-color: #b7cce49c;
  color: #433030;
}

.legend-span {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  border: 1px solid green;
  border-radius: 50%;
  background-color: green;
  margin-top: 2px;
}
.legend-span-remove {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  border: 1px solid #ff3c00;
  border-radius: 50%;
  background-color: #ff3c00;
  margin-top: 2px;
}
.legend {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #007d19;
  justify-content: flex-end;
  margin-right: 20px;
}
.legend-remove {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #f01010c4;
  justify-content: flex-end;
}
.removed-text {
  color: #fc483c;
  font-weight: bolder;
}
p.removed-text-heading {
  color: steelblue;
}
.checkbox-red svg {
  color: white;
  background: white;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.pdf-container {
  display: flex;
  align-content: stretch;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
  overflow-x: visible;
}
.pdf-container canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  max-width: max-content !important;
}
.pdf-button-container {
  display: flex;
}
.pdf-container button {
  padding: 5px 10px;
  margin: 0 10px;
  background-color: #019dc6;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
}

.pdf-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkbox-green input:checked + .MuiSvgIcon-root {
  color: rgb(9 167 9);
  background-color: rgb(159 223 165) !important;
}
.pg-viewer-wrapper .document-container {
  width: auto !important;
}
.thumbsup-container .thumbsup-content {
  display: flex;
}
.thumbsup-content {
  display: flex;
  column-gap: 15px;
}

.education-textfield .thumbsup-content svg {
  fill: #1474c3 !important;
  margin-left: 0 !important;
  top: 0 !important;
}

.education-textfield {
  width: 74%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.data-skills .content .green .education-textfield {
  width: 100%;
  position: relative;
}
.data-skills .education-textfield {
  width: 100%;
}
.data-skills .education-textfield.overall_experience {
  width: 81%;
}

.data.data-skills.thumbsup .thumbsup-content svg {
  fill: #1474c3 !important;
}
.data.thumbsup .thumbsup-content svg {
  fill: #1474c3 !important;
}
.data.thumbsup .thumbsup-content svg {
  fill: #1474c3 !important;
}
.data.data-skills.thumbsup {
  position: relative;
}
.data.thumbsup {
  position: relative;
}
.education-textfield {
  margin-bottom: 16px;
}
.recruiternotes-container {
  display: flex;
  flex-direction: row;
}
.yearsofexperience h6 {
  width: 110px;
}
@media (min-width: 2051px) and (max-width: 4000px) {
  .yearsofexperience h6 {
    position: relative;
    top: -14px;
  }
  .education-textfield {
    width: 100%;
  }
  .skill-container {
    width: 100%;
    flex-direction: column;
  }
  .data-skills .content {
    width: 100%;
  }
  .addskill-container {
    align-items: flex-start;
  }
  .add-skill {
    width: 22vw;
  }
  button.add-skill-btn {
    width: 5vw;
    margin-left: 3vw;
  }

  .update {
    width: 10vw;
  }
  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
  .compare-label {
    width: 100px;
    color: #8b8b8b;
    word-break: break-word;
  }
}

@media (max-width: 2050px) {
  .yearsofexperience h6 {
    position: relative;
    top: -14px;
  }
  .education-textfield {
    width: 100%;
  }
  .skill-container {
    width: 100%;
    flex-direction: column;
  }
  .data-skills .content {
    width: 100%;
  }
  .add-skill {
    width: 22vw;
  }
  button.add-skill-btn {
    width: 5vw;
    margin-left: 3vw;
  }

  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
}
@media (min-width: 1800px) and (max-width: 4000px) {
  .pdf-container {
    height: 100vh !important;
  }
}
@media (max-width: 1800px) {
  .education-textfield {
    width: 90%;
  }
  .skill-container {
    width: 90%;
  }
  .data-skills .content {
    width: 90%;
  }
  button.add-skill-btn {
    width: 5vw;
    margin-left: 3vw;
  }
  .update {
    width: 15vw;
  }
  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
}

@media (max-width: 1650px) {
  .yearsofexperience h6 {
    width: 118px;
    top: -14px;
  }
  .data-skills .content {
    margin-left: 0px;
  }
  .addskill-container {
    margin-left: 6px;
  }
  .skill-container {
    width: 90%;
  }
  .education-textfield {
    width: 90%;
  }
  .data-skills .content {
    width: 90%;
  }
  button.add-skill-btn {
    width: 10vw;
    height: 30px;
    margin-left: 0vw;
  }
  .input-field {
    width: 100%;
  }
  .update {
    width: 15vw;
  }
  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
}

@media (max-width: 1500px) {
  .yearsofexperience h6 {
    width: 111px;
    position: relative;
    top: -14px;
  }
  .data-skills .content {
    width: 90%;
    margin-left: -13px;
  }
  .addskill-container {
    margin-left: -3px;
  }
  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
  .compare-label {
    width: 95px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .select-label {
    margin-right: 32px;
  }
}

@media (max-width: 1360px) {
  .overall-experience-container {
    width: 85%;
  }
  .skill-container {
    width: 72%;
  }
  .education-textfield {
    width: 72%;
  }
  .data-skills .content {
    width: 83%;
    margin-left: -5px;
  }
  .update {
    width: 15vw;
  }
  .Extracted-Resume-Data {
    width: 80%;
  }
  .skills {
    margin-left: 0em;
  }
  .compare-label {
    width: 110px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .select-label {
    margin-right: 32px;
  }
}

@media (max-width: 1200px) {
  .overall-experience-container {
    width: 100%;
  }
  .skill-container {
    width: 90%;
  }
  .education-textfield {
    width: 90%;
  }
  .data-skills .content {
    width: 100%;
  }
  .select-label {
    margin-right: 10px;
    margin-left: -77px;
  }
  .update {
    width: 25vw;
  }
  .Extracted-Resume-Data {
    width: 100%;
    margin: 0;
  }
  .Upload-Resumes {
    display: flex;
    flex-direction: column;
  }

  .resume {
    width: 100%;
  }

  .person-details {
    width: 100%;
  }
  .skills {
    margin-left: 0em;
    width: 70vw;
  }
  .dialog-outer-container .dialog-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .resume-compare {
    width: 100%;
    overflow-x: hidden !important;
  }
  .compare-label {
    width: 105px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .data .compare-textfield {
    width: 70vw;
  }
  .data-extract .compare-textfield {
    width: 70vw;
  }
  .experience-summary,
  .summary,
  .recruiternotes {
    width: 70vw;
  }
  .month-year-select {
    width: 70vw;
  }
  .add-skill {
    width: 60vw;
  }
  .docx-viewer {
    width: 100%;
    height: 50vh;
    border: none;
  }
  .recruiternotes-container h4 {
    width: 93px;
  }
}

@media (max-width: 1040px) {
  .select-label {
    margin-right: 10px;
    margin-left: -77px;
  }
  .yearsofexperience h6 {
    width: 105px;
  }
  .overall-experience-container {
    width: 100%;
  }
  .update {
    width: 25vw;
  }
  .add-resume-outer {
    display: flex;
    flex-direction: column;
  }

  .full-screen-div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .full-screen-div .listed-files-container {
    width: 100%;
    height: 300px;
    overflow: auto;
  }
  .skills {
    margin-left: 0em;
    width: 70vw;
  }
  .docx-viewer {
    width: 100%;
    height: 50vh;
    border: none;
  }

  .compare-label {
    width: 100px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .data .compare-textfield {
    width: 70vw;
  }
  .data-extract .compare-textfield {
    width: 70vw;
  }
  .experience-summary,
  .summary,
  .recruiternotes {
    width: 70vw;
  }
  .month-year-select {
    width: 70vw;
  }
  .add-skill {
    width: 60vw;
  }
  button.add-skill-btn {
    width: 10vw;
    height: 30px;
    margin-left: 0vw;
  }

  .recruiternotes-container h4 {
    width: 93px;
  }
}
@media (max-width: 880px) {
  .yearsofexperience h6 {
    width: 111px;
  }
  .select-label {
    margin-right: 0px;
    margin-left: -11px;
  }
  .education-textfield {
    justify-content: flex-start;
  }
  .update {
    width: 30vw;
  }
  .skills {
    margin-left: 0em;
    width: 70vw;
  }
  .compare-label {
    width: 100px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .data .compare-textfield {
    width: 70vw;
  }
  .data-extract .compare-textfield {
    width: 70vw;
  }
  .experience-summary,
  .summary,
  .recruiternotes {
    width: 70vw;
  }
  .month-year-select {
    width: 70vw;
  }
  .add-skill {
    width: 60vw;
  }
  .error-message-form-year {
    bottom: 40px;
  }
}

@media (max-width: 700px) {
  .recruiternotes-container h4.side-headings {
    width: 120px;
    margin-bottom: 5px;
  }
  .addskill-container .input-field {
    width: 75%;
    margin-left: -6px;
  }
  .error-message-form {
    bottom: 78px;
  }
  .error-message-summary {
    bottom: 38px;
  }
  .yearsofexperience h6 {
    position: relative;
    top: -13px;
    width: 106px;
  }
  .error-message-year {
    bottom: 0px;
    margin-bottom: -25px;
  }
  .error-message-year {
    bottom: 0px;
    margin-bottom: -25px;
  }
  .select-label {
    margin-left: -52px;
    margin-right: 2px;
  }
  .skill-container {
    width: 100%;
    margin-bottom: 15px;
  }
  .update {
    width: 30vw;
  }

  .skills {
    margin-left: 0em;
    margin-right: 10px;
    width: 100%;
  }

  .dialog-outer-container .resume {
    flex: 0 1;
  }
  .data .compare-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .data-extract .compare-textfield {
    width: 100%;
    margin-bottom: 10px;
  }

  .summary,
  .recruiternotes {
    width: 100%;
  }
  .experience-summary {
    width: 100%;
  }
  .month-year-select {
    width: 100%;
  }
  .add-skill {
    width: 90%;
  }
  .data-extract .thumbsup {
    display: flex;
    flex-direction: column;
  }
  .resume-popup {
    display: block;
    background-color: #fff;
    color: #0648a0 !important;
    text-decoration: none !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    font-family: "Outfit", sans-serif;
    padding: 10px 5px;
    width: fit-content;
    margin-top: 10px;
    border: 1px solid #0648a0;
  }
  .Extracted-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #0000002b;
  }

  .inner-resume img {
    display: block;
    width: 100%;
  }
  .compare-label {
    width: 110px;
    color: #8b8b8b;
    word-break: break-word;
  }
  .education-textfield {
    justify-content: flex-start;
    width: 100%;
    margin-right: 10px;
  }
  .data-skills .content {
    margin-top: 25px;
    margin-left: -100px;
    width: 100%;
  }
  .recruiternotes-container {
    display: flex;
    flex-direction: column;
  }

  .addskill-container {
    width: 100%;
  }
  .error-message-form-year {
    bottom: 38px;
  }
  .error-message {
    top: -4px;
    margin-bottom: 0px;
  }
  .error-message-form {
    bottom: 46px;
  }
  .extract-recruiter-note h6 {
    width: 150px;
  }
}

@media (max-width: 640px) {
  .skill-error-message {
    margin-top: 10px;
  }
  .addskill-container .input-field {
    width: 60%;
    margin-left: 0px;
  }
  .thumbsup-content {
    column-gap: 8px;
  }
  .overall-experience-container {
    margin-top: 4px;
  }
  .data.thumbsup.yearsofexperience.compare-information {
    display: flex;
    flex-direction: column;
  }
  .yearsofexperience h6 {
    position: relative;
    top: 0px;
  }
  .error-message-year {
    bottom: 0px;
    margin-bottom: -25px;
  }
  .error-message-form {
    bottom: 78px;
  }
  .error-message-summary {
    bottom: 41px;
  }

  .yearsofexperience h6 {
    top: 0px;
    width: 152px;
  }

  .error-message-year {
    bottom: 0px;
    margin-bottom: -25px;
  }
  .error-message-form-year {
    bottom: 39px;
  }
  .error-message {
    top: -4px;
    margin-bottom: 0px;
  }
  .error-message-form {
    bottom: 40px;
  }
  .thumbsup-content {
    column-gap: 8px;
  }
  .select-label {
    margin-left: -52px;
    margin-right: 14px;
  }
  .skill-container {
    width: 100%;
    margin-bottom: 15px;
  }
  .education-textfield {
    justify-content: flex-start;
    width: 100%;
    margin-right: 10px;
  }
  button.add-skill-btn {
    width: 15vw;
  }
  .update {
    width: 30vw;
  }
  .skills {
    margin-left: 0em;
    margin-right: 10px;
    width: 100%;
  }

  .dialog-outer-container .resume {
    flex: 0 1;
  }
  .data .compare-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .data-extract .compare-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .experience-summary,
  .summary,
  .recruiternotes {
    width: 100%;
  }
  .experience-summary,
  .summary {
    margin-bottom: 10px;
  }
  .month-year-select {
    width: 100%;
  }
  .add-skill {
    width: 90%;
  }
  .resume-popup {
    display: block;
    background-color: #fff;
    color: #0648a0 !important;
    text-decoration: none !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    font-family: "Outfit", sans-serif;
    padding: 10px 5px;
    width: fit-content;
    margin-top: 10px;
    border: 1px solid #0648a0;
  }
  .Extracted-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #0000002b;
  }
  .inner-resume img {
    display: block;
    width: 100%;
  }
  .data-skills .content {
    margin-top: 25px;
    margin-left: -100px;
    width: 100%;
  }
  .recruiternotes-container {
    display: flex;
    flex-direction: column;
  }
  .recruiternotes-container h4.side-headings {
    width: 121px;
    margin-bottom: 10px;
  }
  .addskill-container {
    width: 100%;
  }
  .extract-recruiter-note h6 {
    width: 150px;
  }
}
@media (max-width: 575px) {
  .extract-recruiter-note h6 {
    width: 150px;
  }
  .addskill-container .input-field {
    width: 65%;
    margin-left: 0px;
  }
  .data-extract h6 {
    margin-bottom: 4px;
  }
  .add-skill {
    width: 90%;
  }
  .compare-label {
    width: 100px;
  }

  .skill-error-message {
    margin-top: 8px;
  }
  .overall-experience-container {
    margin-top: 4px;
  }
  .thumbsup-content {
    column-gap: 8px;
  }
  .data.thumbsup.yearsofexperience.compare-information {
    display: flex;
    flex-direction: column;
  }
  .error-message-form {
    bottom: 78px;
  }
  .error-message-summary {
    bottom: 40px;
  }
  .yearsofexperience h6 {
    position: relative;
    top: 0px;
  }
  .error-message-year {
    bottom: 0px;
    margin-bottom: -25px;
  }
  .extract-yearsofexperience {
    align-items: flex-start !important;
  }
  .error-message-form-year {
    bottom: 42px;
  }
  .error-message {
    top: -4px;
    margin-bottom: 0px;
  }
  .error-message-form {
    bottom: 44px;
  }
  .extract-yearsofexperience h6 {
    width: 160px;

    .extract-data {
      flex-direction: column;
    }
    .select-label {
      margin-left: 0px;
      margin-right: 2px;
    }
    .skill-container {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    .education-textfield {
      justify-content: flex-start;
      width: 100%;
      margin-right: 10px;
    }
    .update {
      width: 30vw;
    }

    .skills {
      margin-left: 0em;
      width: 100%;
    }

    .dialog-outer-container .resume {
      flex: 0 1;
    }
    .data .compare-textfield {
      width: 100%;
      margin-bottom: 10px;
    }
    .data-extract .compare-textfield {
      width: 100%;
      margin-bottom: 10px;
    }
    .experience-summary,
    .summary,
    .recruiternotes {
      width: 100%;
    }
    .month-year-select {
      width: 100%;
    }

    .recruiternotes-container h4.side-headings {
      width: 121px;
      margin-bottom: 10px;
    }
    .yearsofexperience h6 {
      width: 155px;
    }
    .pdf-container button {
      margin: 10px 20px;
    }
    .pdf-button-container p {
      margin: 14px 0px;
    }
    .resume-popup {
      display: block;
      background-color: #fff;
      color: #0648a0 !important;
      text-decoration: none !important;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      font-family: "Outfit", sans-serif;
      padding: 10px 5px;
      width: fit-content;
      margin-top: 10px;
      border: 1px solid #0648a0;
    }
    .Extracted-div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #0000002b;
    }
    .inner-resume img {
      display: block;
      width: 100%;
    }
    .data-skills .content {
      margin-top: 25px;
      margin-left: -111px;
      width: 100%;
    }
    .recruiternotes-container {
      display: flex;
      flex-direction: column;
    }
    .addskill-container {
      width: 100%;
    }
    .error-message-year {
      bottom: 0px;
    }
    .thumbsup-content {
      column-gap: 8px;
    }
  }

  @media (max-width: 450px) {
    .data-extract h6 {
      margin-bottom: 4px;
    }
    .skill-error-message {
      margin-top: 5px;
    }
    .overall-experience-container {
      margin-top: 4px;
    }
    .extract-recruiter-note h6 {
      width: 150px;
    }
    .data.thumbsup.yearsofexperience.compare-information {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    .add-skill {
      width: 80%;
    }
    .experience-summary,
    .summary,
    .recruiternotes {
      margin-top: 0px;
    }
    .error-message-form {
      bottom: 41px;
    }
    .error-message-summary {
      position: relative;
      bottom: 40px;
    }
    .yearsofexperience h6 {
      position: relative;
      top: 0px;
    }
    .extract-yearsofexperience {
      align-items: flex-start !important;
    }
    .Extracted-div {
      margin: -4px;
    }
    .contact h6 {
      width: 116px;
    }
    .extract-yearsofexperience h6 {
      width: 160px;
    }
    .select-label {
      margin-left: 0px;
      margin-right: 2px;
    }
    .extract-recruiter-note h6 {
      width: 150px;
    }
    .extract-data {
      flex-direction: column;
    }
    .addskill-container {
      width: 100%;
    }
    button.add-skill-btn {
      width: 28%;
      font-size: 12px;
    }
    .skill-container {
      width: 100%;
      margin-bottom: 10px;
    }
    .education-textfield {
      justify-content: flex-start;
      width: 100%;
      margin-right: 0px;
    }
    .skills {
      margin-left: 0em;
      width: 100%;
      margin-top: 10px;
    }

    .dialog-outer-container .resume {
      flex: 0 1;
    }
    .pdf-container button {
      margin: 10px 20px;
    }
    .pdf-button-container p {
      margin: 14px 0px;
    }
    .data .compare-textfield {
      width: 100%;
      margin-bottom: 10px;
    }
    .data-extract .compare-textfield {
      width: 100%;
      margin-bottom: 10px;
    }
    .experience-summary,
    .summary {
      width: 100%;
    }
    .recruiternotes {
      width: 100%;
    }
    .month-year-select {
      width: 100%;
    }
    .add-skill {
      width: 90%;
    }
    .button-group {
      flex-direction: column;
      width: 70%;
    }
    .resume-popup {
      display: block;
      background-color: #fff;
      color: #0648a0 !important;
      text-decoration: none !important;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      font-family: "Outfit", sans-serif;
      padding: 10px 5px;
      width: fit-content;
      margin-top: 10px;
      border: 1px solid #0648a0;
    }
    .resume-popup-image {
      display: block;
      width: 93%;
      position: fixed;
      top: 237px;
      left: 12px;
    }
    .Extracted-div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #0000002b;
    }

    .inner-resume img {
      display: block;
      width: 100%;
    }
    .data-extract .thumbsup {
      display: flex;
      flex-direction: column;
    }
    .data-skills .content {
      margin-top: 25px;
      margin-left: -111px;
      width: 100%;
    }
    .data.data-skills.thumbsup {
      display: flex;
      flex-direction: column;
    }
    .data-extract .data-skills.thumbsup {
      display: flex;
      flex-direction: column;
    }
    .skill-container {
      width: 100%;
    }
    .error-message-form-year {
      bottom: 36px;
    }
    .error-message {
      top: -4px;
    }
    .error-message-year {
      bottom: 0px;
      margin-bottom: -14px;
    }
    .thumbsup-content {
      column-gap: 8px;
    }
  }
}
