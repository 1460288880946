.upload-main-container {
  border: 2px solid #e6e1e1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  width: 90%;
  flex-direction: column;
  margin: 1vw auto 0;
  border-radius: 5px;
  padding: 0px 55px;
  overflow-y: hidden;
  max-height: max-content;
}
.upload-history-container{
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    height: 100vh;
}
.close-div {
  width: 100%;
  margin-top: 18px;
}
.date-container {
  width: 100%;
}

.date-heading {
  margin-bottom: 2vw;
  margin-top: 1vw;
}

p.date {
  margin-bottom: 1vw;
  color: #004b9e;
  font-size: 18px;
  font-weight: 600;
}

.heading {
  margin-top: 0;
  margin-bottom: 1vw;
  color: #004b9e;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e6e1e1;
  padding: 20px 0;
}

.heading-content {
  width: 100%;
  position: sticky;
  top: 0px;
  background: #fff;
}

.content-div {
  display: flex;
  justify-items: center;
  width: 100%;
  flex-direction: column;
  border-radius: 5px;
  height: auto;
  padding: 20px;
  margin-bottom: 2vw;
}

.content-box {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-box div {
  display: flex;
  align-items: center;
}

.content-box-left div {
  margin-left: 5vw;
}

.content-box-left p:nth-child(odd) {
  width: 75px;
}

.content-box-left p:nth-child(even) {
  width: 20vw;
  word-break: break-word;
}

.content-box-right .parse-button {
  border: 1px solid #004b9e;
  background: transparent;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 15px;
  color: #004b9e;
  font-weight: 500;
  margin-right: 2vw;
}

button.delete-button {
  border: 1px solid #d11a2a;
  background: transparent;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 15px;
  color: #d11a2a;
  font-weight: 500;
}

button.delete-button.loading {
  background-color: #d11a2a;
  color: #fffefe;
  cursor: not-allowed;
  border: 1px solid #f30b1e;
}

button.delete-button:disabled {
  cursor: no-drop !important;
}

.content-box-left svg {
  color: #004b9e;
  height: 35px !important;
  width: 53px !important;
}
.nodata
{
  display:flex;
  justify-content: center;

}
img.no-data-img {
  width: 40vw;
}

.content-box-right img {
  width: 65px;
  height: 23px;
  object-fit: contain;
  object-position: right;
  cursor: pointer;
}

.back-button-div {
  width: 90%;
  margin: 4vw auto 0;
  margin-bottom: 1.5vw;
}

button.back-button {
  background: none;
  border: none;
  font-size: 17px;
  position: relative;
  left: 0.8vw;
  color: #004b9e;
  font-weight: 500;
}

.back-button-div svg {
  position: relative;
  top: 6px;
  color: #004b9e;
  cursor: pointer;
}

.upload-main-container::-webkit-scrollbar {
  width: 10px;
}

.upload-main-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #0649a027;
  border-radius: 10px;
}

.upload-main-container::-webkit-scrollbar-thumb {
  background: #0649a048;
  border-radius: 10px;
}

.upload-main-container::-webkit-scrollbar-thumb:hover {
  background: #0648a0;
}

.close-div img.close-img {
  width: 50%;
  position: relative;
  margin-top: -7px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.pagination-info {
  text-align: center;
  color: #858484;
}
@media (min-width: 300px) and (max-width: 450px) {

  .upload-history-container {
      margin-top: 10px;
    }
  .nodata {
    display: block;
    margin: auto;
  }
  img.no-data-img {
    width: 100%;
    height: 100%;
  }
  .content-box {
    display: block;
  }
  .content-box div {
    align-items: flex-start;
  }
  .content-box-uploadfileicon {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .content-box-left {
    align-items: center !important;
    justify-content: center;
    margin-bottom: 25px;
  }
  .content-box-left div {
    margin-left: 0vw;
  }
  .content-box-left p:nth-child(even) {
    width: 22vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content-box:last-child {
    border-bottom: none;
  }
  .file-container {
    display: flex;
    flex-direction: column;
  }
  .update-time {
    padding: 3vw;
  }
  .content-box-right {
    display: flex !important;
    flex-direction: column;
    margin: 2vw 2vw;
    align-items: center !important;
    justify-content: center;
  }
  .content-box-right .parse-button {
    width: 100%;
    margin: 2vw 2vw;
    padding: 8px 0px;
  }
  .content-box-right .delete-button {
    width: 100%;
    margin: 2vw 2vw;
  }
  .back-button-div{
    display: none;
  }
}

@media screen and (min-width: 451px) and (max-width: 600px) {
  .upload-history-container {
    margin-top: 10px;
  }
  .nodata {
    display: block;
    margin: auto;
  }
  img.no-data-img {
    width: 100%;
    height: 100%;
  }
  .content-box {
    display: block;
  }
  .content-box div {
    align-items: flex-start;
  }
  .content-box-uploadfileicon {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .content-box-left div {
    margin-left: 0vw;
  }
  .content-box-left p:nth-child(even) {
    width: 42vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content-box-left {
    align-items: center !important;
    justify-content: center;
    margin-bottom: 25px;
  }
  .content-box:last-child {
    border-bottom: none;
  }
  .file-container {
    display: flex;
    flex-direction: column;
  }
  .update-time {
    padding-bottom: 3vw;
  }
  .content-box-right {
    display: flex !important;
    flex-direction: column;
    margin: 2vw 2vw;
    align-items: center !important;
    justify-content: center;
  }
  .content-box-right .parse-button {
    width: 100%;
    margin: 2vw 2vw;
    padding: 8px 0px;
  }
  .content-box-right .delete-button {
    width: 100%;
    margin: 2vw 2vw;
  }
  .back-button-div{
    display: none;
  }
}


@media screen and (min-width: 601px) and (max-width: 768px) {
  .upload-history-container {
    margin-top: 10px;
  }
  .nodata {
    display: block;
    margin: auto;
  }
  img.no-data-img {
    width: 100%;
    height: 100%;
  }
  .content-box {
    display: block;
  }

  .content-box div {
    align-items: flex-start;
  }
  .content-box-uploadfileicon {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .content-box-left p:nth-child(even) {
    width: 40vw;
  }
  .content-box-left {
    align-items: center !important;
    justify-content: center;
    margin-bottom: 25px;
  }
  .content-box:last-child {
    border-bottom: none;
  }
  .file-container {
    display: flex;
    flex-direction: column;
  }
  .update-time {
    padding-bottom: 3vw;
  }
  .content-box-left div {
    margin-left: 0vw;
  }
  .content-box-right {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    margin: 2vw 2vw;
  }
  .content-box-right .parse-button {
    width: 100%;
    margin: 2vw 2vw;
    padding: 8px 0px;
  }
  .content-box-right .delete-button {
    width: 100%;
    margin: 2vw 2vw;
  }
  .back-button-div{
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 850px) {
  img.no-data-img {
    width: 100%;
    height: 100%;
  }
  .content-box {
    display: block;
  }
  .content-box div {
    align-items: flex-start;
  }
  .content-box-uploadfileicon {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .content-box-left div {
    margin-left: 0vw;
  }
  .content-box-left p:nth-child(even) {
    width: 60vw;
  }
  .content-box-left {
    align-items: center !important;
    justify-content: center;
    margin-bottom: 25px;
  }
  .content-box:last-child {
    border-bottom: none;
  }
  .file-container {
    display: flex;
    flex-direction: column;
  }
  .update-time {
    padding-bottom: 3vw;
  }
  .content-box-right {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    margin: 2vw 2vw;
  }
  .content-box-right .parse-button {
    width: 100%;
    margin: 2vw 2vw;
  }
  .content-box-right .delete-button {
    width: 100%;
    margin: 2vw 2vw;
  }
}
@media (min-width: 913px) and (max-width: 991px) {
 
  .content-box-left p:nth-child(even) {
    width: 25vw;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .content-box-left p:nth-child(even) {
    width: 30vw;
  }
}
@media (min-width: 2500px) and (max-width: 3500px) {
  .content-box-left p:nth-child(even) {
    width: 35vw;
  }
}
