.exist {
  font-size: 20px;
  font-family: "Outfit", sans-serif;
}
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 90vh;
}
.home-button {
  font-size: 21px;
  font-family: ui-serif;
  color: #4545bd;
  font-weight: 600;
}
u {
  color: #00ba2c;
}

.main div#lottie {
  overflow: visible !important;
  height: 400px !important;
  width: 600px !important;
}

.main div#lottie svg {
  height: 500px !important;
  width: 600px !important;
}

.error-div {
  text-align: center;
}
.error-div .error {
  font-size: 3em;
  color: #0648a0;
  font-weight: bolder;
  text-align: center;
  font-family: "Outfit", sans-serif;
}
@media (max-width: 700px) {
  .main div#lottie {
    overflow: visible !important;
    height: 400px !important;
    width: 300px !important;
  }
  
  .main div#lottie svg {
    height: 400px !important;
    width: 300px !important;
  }
}