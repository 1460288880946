body {
  overflow-x: hidden;
}
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #e9e9e9;
  padding: 20px;
  align-items: center;
  margin-top: 7vh;
}
.logo img {
  width: 150px;
  height: auto;
}
.vylabs-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vylabs-content p {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
}
.vylabsLogo {
  width: 8vw;
  height: auto;
  margin-top: 10px;
}
.copyright-text {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .footer {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .logo,
  .vylabs-content,
  .copy-right {
    justify-content: center;
  }
  .logo img {
    width: 24vw;
  }
  .vylabsLogo {
    width: 20vw;
  }
}
@media screen and (min-width: 601px) and (max-width: 712px) {
  .footer {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .logo,
  .vylabs-content,
  .copyright {
    justify-content: center;
  }
  .logo img {
    width: 22vw;
  }
  .vylabsLogo {
    width: 19vw;
  }
}
@media screen and (min-width: 713px) and (max-width: 912px) {
  .footer {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .logo,
  .vylabs-content,
  .copy-right {
    justify-content: center;
  }
  .logo img {
    width: 14vw;
  }
  .vylabsLogo {
    width: 11vw;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .footer {
    padding: 14px;
  }
  .logo img {
    width: 15vw;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .logo img {
    width: 12vw;
  }
  .vylabsLogo {
    width: 10vw;
  }
}
