body{
  font-family: "Outfit", sans-serif;
}
.expiry-login-container-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}
.expiry-login-container {
  width: 55%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  box-shadow: 1px 1px 5px #828282;
  padding: 60px;
  border-radius: 15px;
  gap: 20px;
}
.expiry-login-container img {
  width: 100%;
  height: 200px;
}
.expiry-login-container h1 {
  text-align: center;
  font-size: 25px;
  word-wrap: break-word;
}
.expiry-login-container button.re-login {
  width: auto;
  margin: 0 auto;
  padding: 10px 25px;
  background: #0648A0;
  color: #fff;
  border: none;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
}
@media (max-width: 500.98px) {
  .expiry-login-container {
    width: 90%;
  }
  .expiry-login-container h1{
    font-size: 20px;
  }
  .expiry-login-container button.re-login{
    font-size: 12px;
  }
}
@media (max-width: 800.98px) {
  .expiry-login-container {
    width: 90%;
  }
}
@media (max-width: 1000.98px) {
  .expiry-login-container {
    width: 85%;
  }
}