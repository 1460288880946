@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scrollbar-width: thin;
  scrollbar-color: #0649a048 #0649a027;
}

html::-moz-scrollbar:hover {
  background-color: #0648a0;
}
html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: #004e9c62;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: #004e9c;
}

body {
  font-family: "Outfit", sans-serif;
}

.main-candidate {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  height: 100vh;
}

.resume-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vw 2vw 25px 2vw;
  background-color: #f1f7ff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  width: 65vw;
  margin: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 5vh;
}
.choose-file {
  color: #004b9e;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
}
.supported-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.supported-file {
  font-size: 13px;
  font-weight: 500;
  color: #88898a;
  font-family: "Outfit", sans-serif;
  margin-top: 0px;
}
.upload-heading {
  color: black;
  font-size: 22px;
  font-weight: 400;
  font-family: "Belanosima", sans-serif;
}
.drag-drop {
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.resume-upload-container h2 {
  margin: 15px 0px;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
}
form {
  width: 100%;
}
.resume-upload-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-upload-container input[type="file"] {
  margin-bottom: 10px;
}

.resume-upload-container button {
  width: 130px;
  height: 37px;
  background-color: #0648a0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
}

.resume-upload-container button:hover {
  background-color: #0056b3;
}
.error-message {
  color: red;
  font-size: 15px;
  position: relative;
  top: 0;
}
.resume-filename {
  font-weight: 500;
}
.skill-error-message {
  color: red;
  font-size: 15px;
  margin-top: 5px;
}

.file-drop-container {
  width: 57vw;
  padding: 95px 20px;
  border: 2px dashed #004b9e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  background-color: #e9f1fd;
  border-radius: 5px;
  height: 60vh;
}

.add-resume-outer {
  display: flex;
  width: 80vw;
  margin: auto;
  background: #f1f7ff;
  align-content: center;
  padding: 25px 20px;
  column-gap: 6px;
  flex-direction: column;
  margin-top: 5%;
}

.add-resume-outer h3 {
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
  font-size: 24px;
}
.full-screen-div {
  display: flex;
  width: 100%;
  margin: auto;
  background: transparent;
  align-content: flex-start;
  padding: 0;
  column-gap: 10px;
}
.full-screen-div .listed-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  margin: 0;
  box-shadow: none;
  justify-content: flex-start;
  height: 60vh;
  overflow: auto;
}
.full-screen-div .listed-files-container .list-files-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  height: 60px;
}
.full-screen-div .file-drop-container {
  width: 100% !important;
  border: 2px dashed #004b9e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
}
.upload-section-btn {
  column-gap: 35px;
  display: flex;
  margin-top: 25px;
  justify-content: center;
}

.upload-section-btn button.cancel {
  padding: 6px 20px;
  background-color: transparent;
  color: #88898a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  box-shadow: none;
  border: 1px solid #88898a;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}

.upload-section-btn button.import {
  padding: 8px 25px;
  color: #7a7a7a;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  text-align: center;
  background-color: #004e9c;
  color: #fff;
}
.list-files-icon svg {
  width: 50px !important;
  height: 40px !important;
  color: #004b9e;
}

.list-files-icon {
  display: flex;
  column-gap: 6px;
  align-items: center;
  font-size: 15px;
  font-family: "Belanosima";
  border-radius: 5px;
  word-break: break-word;
}

.full-screen-div .listed-files-container::-webkit-scrollbar {
  width: 10px !important;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-thumb {
  background: #004e9c62;
  border-radius: 10px !important;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-thumb:hover {
  background: #004e9c;
}



@media screen and (min-width: 250px) and (max-width: 600px) {
  .resume-filename {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .drag-drop {
    margin-top: 28px;
    font-size: 13px;
    text-align: center;
  }
  .file-drop-container {
    overflow: hidden !important;
    width: 75vw;
  }

  .resume-upload-container {
    width: 90vw;
  }
  .upload-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .choose-file {
    font-size: 13px !important;
  }
  .supported-container {
    display: block;
    text-align: center;
  }
  .supported-file {
    font-size: 12px;
  }
  .add-resume-outer .drag-drop {
    text-align: center;
  }
  .add-resume-outer .supported-file {
    text-align: center;
  }
}

@media screen and (min-width: 601px) and (max-width: 899px) {
  .file-drop-container {
    overflow: hidden !important;
    width: 70vw;
  }

  .drag-drop {
    text-align: center;
  }

  .resume-upload-container {
    width: 80vw;
  }

  .upload-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 4501px) and (max-width: 6000px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    height: 67px;
    padding: 17px 22px;
    width: 100%;
  }
  .upload-heading {
    font-size: 22px;
  }
}

@media only screen and (min-width: 3100px) and (max-width: 4500px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    height: 100px;
    padding: 22px 22px;
    width: 100%;
  }
  .upload-heading {
    font-size: 22px;
  }
}
@media only screen and (min-width: 2701px) and (max-width: 3100px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    height: 100px;
    padding: 22px 22px;
    width: 100%;
  }
  .upload-heading {
    font-size: 22px;
  }
}
@media only screen and (min-width: 2001px) and (max-width: 2700px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    height: 100px;
    padding: 16px 22px;
    width: 100%;
  }
  .upload-heading {
    font-size: 22px;
  }
  .add-resume-outer {
    top: 100px;
  }
}
@media only screen and (min-width: 1801px) and (max-width: 2000px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    padding: 17px 22px;
    width: 100%;
  }
  .upload-heading {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .drag-drop {
    font-size: 16px;
  }
  .navbar {
    padding: 17px 22px;
    width: 97%;
  }
  .upload-heading {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1517px) and (max-width: 1600px) {
  .drag-drop {
    font-size: 16px;
  }
  .upload-heading {
    font-size: 22px;
  }
}
