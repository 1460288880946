.login-button.update-loading {
  background-color: #eceaeab9;
  color: #969696;
  cursor: not-allowed;
  border: 1px solid #969696;
  padding: 10px 3px 10px 24px;
}
.login-button.update-loading span {
  position: absolute;
  left: -10px;
}

.updatepassword label
{
  font-size: 15px;
  font-weight: 500;
}
.updatepassword .input-field {
  margin-bottom: 60px;
}
.update-password-image {
  width: 50%;
}

img.side-img {
  width: 50vw;
}
.home-button-div {
  width: 90%;
  margin: 2vw auto 0;
  margin-bottom: -3.5vw;
}
.home-button-div svg {
  position: relative;
  top: 6px;
  color: #004b9e;
  cursor: pointer;
}
.update-img-container {
  display: block;
  align-self: center;
}

@media (max-width: 450.97px) {
  .side-img {
    display: none;
  }
  .update-img-container {
    align-self: start;
  }
  .updatepassword .login {
    background-color: white;
  }
  .updatepassword .login-heading {
    margin-top: 28px;
  }
  .updatepassword .input-field {
    margin-bottom: 70px;
  }
  .home-button-div {
  display: none;
}
}
@media screen and (min-width: 450.98px) and (max-width: 575.98px) {
  .side-img {
    display: none;
  }
  .updatepassword .login {
    background-color: white;
  }
  .update-img-container {
    align-self: start;
  }
  .home-button-div {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .side-img {
    display: none;
  }
  .update-img-container {
    align-self: start;
  }
  .updatepassword .login {
    background-color: white;
  }
  .home-button-div {
    display: none;
  }

}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .side-img {
    display: none;
  }
  .update-img-container {
    align-self: start;
  }
  .updatepassword .login {
    background-color: white;
  }
  .updatepassword .login-form {
    margin: 0;
  }
  .updatepassword .login-heading {
    margin-top: 35px;
  }
  .updatepassword .input-field {
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .side-img {
    display: none;
  }
  .updatepassword .login
  {
    align-items: center;
    justify-content: center;
  }
  .updatepassword .login-form {
    width: 75%;
  }
  .updatepassword .login-heading {
    text-align: center;
  }
  .updatepassword .login-button {
    align-self: center;
  }
  .updatepassword .input-field {
    margin-bottom: 70px;
  }
}


@media screen and (min-width: 2501px) and (max-width: 3500px) {
  button .back-button {
    font-size: 23px;
  }
 
  .updatepassword .error-message-login {
    font-size: 16px;
  }
  
}
