.forgot-password-background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 51%;
  background-image: url("../Images/login_background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.forgot-password-background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 51%;
  background-image: url("../Images/login_background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}
.login-animation-forgot-password {
  margin-top: 15px;
}

.login-animation-forgot-password {
  padding: 15px;
  width: 36vw;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 10%;
  margin-top: 10vh;
}

.resetpassword .login-input-field {
  margin-bottom: 60px;
}

.password-back-button-div {
  width: 90%;
  margin-bottom: 3vw;
  margin-left: -7vw;
}

.resetpassword .password-back-button-div {
  width: 90%;
  margin-bottom: 1vw;
  margin-left: -7vw;
  margin-top: 2vw;
}
.resetpassword .copyright {
  margin: 50px 0px 30px 0px;
}

button.password-back-button {
  background: none;
  border: none;
  font-size: 17px;
  position: relative;
  left: 0.8vw;
  color: #004b9e;
  font-weight: 500;
}

.password-back-button-div svg {
  position: relative;
  top: 6px;
  color: #004b9e;
  cursor: pointer;
}

.password-copyright {
  display: flex;
  color: #00000079;
  align-content: center;
  justify-content: center;
  margin: 85px 0px 0px 0px;
}

.forgot-password .login-input-field {
  margin-bottom: 30px;
}

@media (max-width: 590.98px) {
  .forgot-password-page {
    background-color: aliceblue;
  }

  .forgot-password .login-input-field {
    margin-bottom: 45px;
  }

  .password-back-button-div {
    display: none;
  }

  .resetpassword .password-back-button-div {
    display: none;
  }

  .forgot-password .login {
    justify-content: baseline !important;
  }

  .forgot-password-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 17vh;
  }

  .forgot-password .login-heading {
    margin-bottom: 10px;
  }

  .password-copyright {
    margin: 55px 0px 20px 0px;
  }

  .resetpassword .login-input-field {
    margin-bottom: 62px;
  }

  .forgotPasswordButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .forgot-password .login-button {
    width: 80px;
    height: 40px;
    margin: auto;
  }

  .resetpassword .login-button {
    width: 80px;
    height: 40px;
    margin: auto;
  }

  .reset-button {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 591px) and (max-width: 768px) {
  .forgot-password .login-input-field {
    margin-bottom: 45px;
  }

  .forgot-password .login {
    justify-content: flex-start !important;
  }

  .password-back-button-div {
    display: none;
  }

  .resetpassword .password-back-button-div {
    display: none;
  }
  .forgot-password-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-top: 3vh;
  }

  .forgot-password .login-heading {
    margin-bottom: 10px;
  }
  .password-copyright {
    margin: 55px 0px 20px 0px;
  }

  .forgotPasswordButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .forgot-password .login-button {
    width: 80px;
    height: 40px;
    margin: auto;
  }

  .resetpassword .login-button {
    width: 80px;
    height: 40px;
    margin: auto;
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  .forgot-password-form {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    width: 60%;
    margin-top: 7vh;
  }

  .forgot-password .login-input-field {
    margin-bottom: 45px;
  }

  .resetpassword .password-back-button-div {
    margin-top: 4vw;
    margin-left: -3px;
  }

  .password-back-button-div {
    margin-bottom: 1vw;
    margin-left: -3px;
  }

  .forgot-password .login-heading {
    margin-bottom: 10px;
  }

  .password-copyright {
    margin: 55px 0px 20px 0px;
  }

  .forgot-password-form {
    margin-top: 7vh;
  }

  .back-button-forgot {
    display: none;
  }

  .reset-button {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 992px) and (max-width: 1109.98px) {
  .signup-container .login-button {
    margin-top: 25px;
  }
  .resetpassword .login-input-field {
    margin-bottom: 60px;
  }
  .back-button-forgot {
    display: none;
  }
  .reset-button {
    display: flex;
  }
}

@media (min-width: 1110px) and (max-width: 6000px) {
  .back-button-forgot {
    display: none;
  }
}
